
import * as ipxRuntime$RC3xo7mlX5 from '/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as cacheIpxRuntime$nxc8j2I0Kt from '/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/providers/cache_ipx.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "modified": {
      "modifiers": {
        "fit": "cover",
        "format": "webp",
        "quality": 80,
        "loading": "lazy"
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "e-store.biz"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$RC3xo7mlX5, defaults: {"maxAge":31536000} },
  ['cacheIpx']: { provider: cacheIpxRuntime$nxc8j2I0Kt, defaults: {"storageUrl":"https://e-store.biz/storage"} }
}
        